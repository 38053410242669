import React, { Children } from "react";
import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { forTabletPortraitDown } from "../../tokens/media-queries";
import Carousel from "../carousel/Carousel";

/**
 * Implementation:
 *
 * https://uxdesign.cc/creating-horizontal-scrolling-containers-the-right-way-css-grid-c256f64fc585
 */
const DashboardCarouselElement = styled.div<{ socialCarousel?: boolean }>`
  --carousel-scroll-track-gutter: ${pxToRem(20)};
  --carousel-scroll-item-width: ${pxToRem(362)};
  @media ${forTabletPortraitDown} {
    --carousel-scroll-item-width: ${(props) =>
      props.socialCarousel ? pxToRem(180) : pxToRem(300)};
    margin-right: calc(var(--carousel-scroll-track-gutter) * -1);
  }

  position: relative;
  margin-left: calc(var(--carousel-scroll-track-gutter) * -1);

  [data-carousel-scroll-track] {
    display: grid;
    align-items: flex-start;
    grid-gap: var(--carousel-scroll-track-gutter);
    grid-template-columns:
      var(--carousel-scroll-track-gutter)
      repeat(var(--carousel-num-items, 6), var(--carousel-scroll-item-width))
      var(--carousel-scroll-track-gutter);
  }
  [data-carousel-scroll-track]:before,
  [data-carousel-scroll-track]:after {
    content: "";
  }
`;

type Props = {
  socialCarousel?: boolean;
};
const DashboardCarousel: React.FC<Props> = (props) => {
  return (
    <DashboardCarouselElement socialCarousel={props.socialCarousel}>
      <Carousel
        childSelector="[data-content-card]"
        style={{ "--carousel-num-items": Children.count(props.children) }}
      >
        {props.children}
      </Carousel>
    </DashboardCarouselElement>
  );
};

export default DashboardCarousel;
