import React, { memo } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import { forTabletPortraitDown } from "../../tokens/media-queries";
import {
  IndexSocialCardGroup,
  indexSocialLinks
} from "../card/social-card/IndexSocialCard";
import { SocialCard, socialLinks } from "../card/social-card/SocialCard";
import DashboardCarousel from "./DashboardCarousel";

export type Props = {
  limit?: boolean;
  isOnIndex?: boolean;
};

export const DashboardSocialCards: React.FC<Props> = memo(
  ({ limit = true, isOnIndex = false }) => {
    const isTabletPortraitDown = useMediaQuery(forTabletPortraitDown);
    const items = isOnIndex ? indexSocialLinks : socialLinks;
    const content = (limit ? items.slice(0, 6) : items).map(
      ({ platform, label, href, icon, tagline }, i) => (
        <SocialCard
          key={i}
          platform={platform}
          href={href}
          label={label}
          icon={icon}
          tagline={tagline}
        />
      )
    );
    return isTabletPortraitDown ? (
      <DashboardCarousel socialCarousel={true}>{content}</DashboardCarousel>
    ) : (
      <IndexSocialCardGroup>{content}</IndexSocialCardGroup>
    );
  }
);
DashboardSocialCards.displayName = "DashboardSocialCards";
