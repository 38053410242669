import { NextPage } from "next";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import styled from "styled-components";
import {
  getFiltersFromQuery,
  hasTagFilter,
  stringifyFilters,
  toStrippedArray
} from "../api-utils/item-filters";
import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";
import Button from "../components/buttons/Button";
import Heading from "../components/heading/Heading";
import ContentCard from "../components/item/content-card/ContentCard";
import { getPathLabel } from "../components/navigation/shared";
import CategoryNavigationAndFilters from "../components/page-partials/CategoryNavigationAndFilters";
import {
  ContentGrid,
  ContentGridItem
} from "../components/page-partials/ContentCardGrid";
import { PageContainer } from "../components/page-partials/PageContainer";
import { PaginationContainer } from "../components/page-partials/PaginationContainer";
import { Pagination } from "../components/pagination/Pagination";
import { ensureAllowedSort } from "../components/search/shared";
import { Stack } from "../components/Stack";
import Text from "../components/text/Text";
import { Facets, Filters, Item } from "../data/db/types";
import { useSWR } from "../hooks/useSWR";
import { publicUrl } from "../utils/url-utils";

const NoSearchWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const ImageNoSearchResult = styled.img`
  width: 100%;
`;

export const LandingGridContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

type ContentLandingData = {
  title: string;
  items: Item[];
  href: string;
};

export type PageData =
  | {
      items: Item[];
      pages: number;
      totalItems: number;
    }
  | {
      landingFields: ContentLandingData[];
    };

export type Props = {
  fallbackToCurated?: boolean;
  facets?: Facets;
  filters?: Filters;
  data: PageData;
};

const hitsSelector = (data?: PageData) => {
  return data && "totalItems" in data ? data.totalItems : undefined;
};
const itemsSelector = (data?: PageData) => {
  return data && "items" in data && data.items.length > 0
    ? data.items
    : undefined;
};
const pagesSelector = (data?: PageData) => {
  return data && "pages" in data ? data.pages : 1;
};

const ContentGridPage: NextPage<Props> = ({
  data: fallbackData,
  fallbackToCurated = false,
  facets
}) => {
  const router = useRouter();
  const path = router.asPath.split("?")[0];
  const filters = getFiltersFromQuery(router.query);
  const sort = ensureAllowedSort(
    toStrippedArray(router.query.sort)[0] || "created"
  );
  filters.sort = sort;
  const categoryLabel =
    path === "/content" ? "All content" : getPathLabel(path);
  const pageCanonicalUrl =
    filters.page === 1
      ? publicUrl(path)
      : publicUrl(`${path}?page=${filters.page}`);
  const heading = categoryLabel;
  /**
   * If we want curated content by
   * default (i.e. the `All` page)
   * we query a different api here
   */
  const apiUrl =
    fallbackToCurated && !hasTagFilter(filters) && !sort
      ? "/api/content"
      : `/api/item?${stringifyFilters(filters)}`;
  const { data: res } = useSWR<PageData>(apiUrl, {
    fallbackData
  });

  const data = res && !("error" in res) ? res : undefined;

  return (
    <>
      <Head>
        <title>{categoryLabel} | Essential Cardano</title>
        <meta
          name="description"
          content={`Find ${categoryLabel} on Essential Cardano`}
        />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="canonical" href={pageCanonicalUrl} />
      </Head>
      <Breadcrumbs />
      <PageContainer>
        <CategoryNavigationAndFilters
          _tags={facets?._tags}
          hits={hitsSelector(data)}
          headingContent={<Heading level="1">{heading}</Heading>}
        />
        {data && "landingFields" in data ? (
          <Stack level="1">
            {data.landingFields.map(({ title, items }, i) =>
              items.length > 0 ? (
                <LandingGridContainer key={i}>
                  <Heading level="2">{title}</Heading>
                  <ContentGrid>
                    {items.map((item, idx) => {
                      return <ContentCard key={idx} item={item} />;
                    })}
                  </ContentGrid>
                </LandingGridContainer>
              ) : null
            )}
          </Stack>
        ) : (
          <>
            <ContentGrid>
              {itemsSelector(data)?.map((item) => {
                return (
                  <ContentGridItem key={item._id}>
                    <ContentCard item={item} />
                  </ContentGridItem>
                );
              }) ?? (
                <NoSearchWrapper>
                  <ImageNoSearchResult
                    src="/no-result-graphic.png"
                    alt="No search results"
                  />
                  <Text element="p">
                    It looks like we don&apos;t have any {categoryLabel} yet
                  </Text>
                  <Link href="/contribute" passHref={true}>
                    <Button as="a">Contribute</Button>
                  </Link>
                </NoSearchWrapper>
              )}
            </ContentGrid>
            <PaginationContainer>
              <Pagination pages={pagesSelector(data)} />
            </PaginationContainer>
          </>
        )}
      </PageContainer>
    </>
  );
};
export default ContentGridPage;
