import React from "react";
import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import colors from "../../tokens/colors";
import { forTabletPortraitDown } from "../../tokens/media-queries";
import Button from "../../components/buttons/Button";
import { guide } from "../../links";

const DashboardBannerElement = styled.a`
  margin-top: ${pxToRem(50)};
  margin-bottom: ${pxToRem(40)};
  text-decoration: none;
  padding: var(--spacing-large);
  background: ${colors.others.bannerBackground};
  color: ${colors.primary.white};
  border-radius: ${pxToRem(20)};
  background-image: url("/essential-cardano-guide.jpg");
  background-position: 100% calc(80% - ${pxToRem(50)});
  background-repeat: no-repeat;
  height: ${pxToRem(277)};
  display: flex;
  flex-direction: column;
  @media ${forTabletPortraitDown} {
    height: auto;
  }
  cursor: pointer;
`;

const DashboardGuideBannerHeading = styled.h2`
  font-size: ${pxToRem(30)};
  font-weight: 400;
  padding: ${pxToRem(20)};
  padding-bottom: ${pxToRem(0)};
  margin: 0;
  max-width: ${pxToRem(380)};
`;

const DashboardGuideBannerHeadingBold = styled(DashboardGuideBannerHeading)`
  font-weight: 700;
  line-height: ${36 / 30};
  padding-top: ${pxToRem(5)};
`;

const DashboardGuideBannerText = styled.p`
  padding-left: ${pxToRem(20)};
  margin-top: ${pxToRem(4)};
`;

const LearnMoreButton = styled(Button)`
  align-self: left;
  width: ${pxToRem(150)};
  margin-left: ${pxToRem(17)};
  margin-top: ${pxToRem(10)};
`;

type Props = {};
const DashboardGuideBanner: React.FC<Props> = () => {
  return (
    <DashboardBannerElement {...guide}>
      <DashboardGuideBannerHeading>The</DashboardGuideBannerHeading>
      <DashboardGuideBannerHeadingBold>
        Essential Cardano Guide to the Ecosystem
      </DashboardGuideBannerHeadingBold>
      <DashboardGuideBannerText>
        is your overview of the people and projects building on Cardano.
      </DashboardGuideBannerText>
      <LearnMoreButton as="span" appearance="banner" size="medium">
        Learn More
      </LearnMoreButton>
    </DashboardBannerElement>
  );
};

export default DashboardGuideBanner;
