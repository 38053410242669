import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { forTabletPortraitUp } from "../../../tokens/media-queries";

import {
  DISCORD_COMMUNITY,
  GITHUB,
  LINKEDIN,
  REDDIT_CARDANO_STAKE_POOLS,
  SocialCardOuter,
  TWITTER,
  YOUTUBE
} from "./SocialCard";

export const IndexSocialCardGroup = styled.div`
  display: flex;
  justify-content: space-between;

  min-width: calc(10% - ${pxToRem(35)});

  margin-left: ${pxToRem(-13)};
  margin-right: ${pxToRem(-13)};

  & > ${SocialCardOuter} {
    margin: var(--spacing-default);
    min-width: unset;
  }

  @media ${forTabletPortraitUp} {
    & > ${SocialCardOuter} {
      flex-shrink: 1;
      flex-basis: calc(50% - ${pxToRem(10)});
    }
  }
`;

export const indexSocialLinks = [
  TWITTER,
  YOUTUBE,
  LINKEDIN,
  DISCORD_COMMUNITY,
  GITHUB,
  REDDIT_CARDANO_STAKE_POOLS
] as const;
