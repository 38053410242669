import { pxToRem } from "@input-output-hk/px-to-rem";
import { Children, useEffect, useReducer, useRef } from "react";
import styled from "styled-components";
import useIsomorphicLayoutEffect from "../../hooks/useIsomorphicLayoutEffect";
import { forPhoneOnly } from "../../tokens/media-queries";
import ChevronLeft from "../icons/ChevronLeft";
import ChevronRight from "../icons/ChevronRight";

const ScrollButton = styled.button`
  border: 0;
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
  border-radius: 50%;
  color: var(--theme-color-primary);
  background-color: var(--default-bg-color);
  box-shadow: ${pxToRem(-1)} ${pxToRem(2)} ${pxToRem(10)}
      rgba(255, 255, 255, 0.25),
    ${pxToRem(1)} ${pxToRem(2)} ${pxToRem(10)} rgba(43, 78, 167, 0.15);
  position: absolute;
  top: 50%;
  &[data-back] {
    right: 100%;
    margin-left: var(--spacing-large);
    transform: translate(0, -50%);
  }
  &[data-forward] {
    left: 100%;
    margin-right: var(--spacing-large);
    transform: translate(0, -50%);
  }
  [hidden] {
    display: none;
  }
  cursor: pointer;
  touch-action: manipulation;
  @media ${forPhoneOnly} {
    display: none;
  }
`;

const CarouselElement = styled.div`
  position: relative;
`;

const ScrollContainer = styled.div`
  padding-top: var(--spacing-default);
  padding-bottom: var(--spacing-default);
  position: relative;
  scroll-snap-type: mandatory;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-padding-left: var(--carousel-scroll-track-gutter, ${pxToRem(20)});

  @media not all and (hover: none) {
    /* Hide the scrollbars */
    &::-webkit-scrollbar {
      display: none;
    }
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
  }
`;
/**
 * All children live inside the track
 * and we set the scroll-snap-align
 * here
 */
const ScrollTrack = styled.div`
  & > * {
    scroll-snap-align: start;
    flex-shrink: 0;
  }
`;

const defaultChildSelector = `${ScrollTrack} > *`;

type ButtonAction = {
  type: "FORWARD" | "BACKWARD";
};
type DidScrollAction = {
  type: "DID_SCROLL";
  idx: number;
};

type State = {
  idx: number;
  back: boolean;
  forward: boolean;
};

type Props = React.ComponentPropsWithoutRef<typeof CarouselElement> & {
  childSelector?: string;
  scrollBy?: number;
  onScroll?: () => void;
};

const __DEBUG__ = false; //process.env.NODE_ENV !== 'production';

const Carousel: React.FC<Props> = ({
  scrollBy = 1,
  childSelector = defaultChildSelector,
  children,
  onScroll,
  ...props
}) => {
  const listRef = useRef<HTMLDivElement>(null);
  const getCountRef = useRef(() => Children.toArray(children).length);
  useEffect(() => {
    getCountRef.current = () => Children.toArray(children).length;
  }, [children]);

  const [state, dispatch] = useReducer(
    (s: State, action: ButtonAction | DidScrollAction): State => {
      const count = getCountRef.current();
      const idx = s.idx;
      let nextIdx = idx;
      switch (action.type) {
        /**
         * Forward button clicked
         */
        case "FORWARD":
          nextIdx = (idx + scrollBy) % count;
          break;
        /**
         * Backward button clicked
         */
        case "BACKWARD":
          nextIdx = (idx - scrollBy + count) % count;
          break;
        /**
         * User manually scrolled (touch/scroll wheel)
         */
        case "DID_SCROLL":
          if (action.idx < 0 || action.idx === s.idx) {
            return s;
          }
          nextIdx = action.idx;
          break;
      }

      const nextState = {
        idx: nextIdx,
        forward: nextIdx < count - 1,
        back: nextIdx > 0
      };
      if (listRef.current) {
        const parent = listRef.current;
        const els = Array.from(
          parent.querySelectorAll(childSelector)
        ) as HTMLElement[];
        const el = els[nextState.idx];
        if (el) {
          if (__DEBUG__) {
            els.forEach((e) => (e.style.outline = ""));
            el.style.outline = `${pxToRem(5)} solid red`;
          }
          const elRect = el.getBoundingClientRect();
          const parentRect = parent.getBoundingClientRect();

          const scrollLeft = parent.scrollLeft;
          const targetScollLeft = scrollLeft + (elRect.left - parentRect.left);
          const scrollLeftLimit = parent.scrollWidth - parent.offsetWidth;

          if (targetScollLeft >= scrollLeftLimit) {
            nextState.forward = false;
          }
          if (action.type !== "DID_SCROLL") {
            parent.scrollTo({
              left: targetScollLeft,
              behavior: "smooth"
            });
          }
        }
      }
      return nextState;
    },
    { idx: 0, back: false, forward: getCountRef.current() > 1 }
  );
  useIsomorphicLayoutEffect(() => {
    let tid: number | null = null;
    const handler = () => {
      if (tid) {
        clearTimeout(tid);
      }
      tid = window.setTimeout(() => {
        if (listRef.current) {
          let gotoIdx = -1;
          const parent = listRef.current;
          const parentRect = parent.getBoundingClientRect();

          const els = Array.from(
            parent.querySelectorAll(childSelector)
          ) as HTMLElement[];

          for (const el of els) {
            const elRect = el.getBoundingClientRect();
            const targetScrollLeft = Math.floor(elRect.left - parentRect.left);
            if (targetScrollLeft >= 0) {
              if (__DEBUG__) {
                els.forEach((e) => (e.style.outline = ""));
                el.style.outline = `${pxToRem(5)} solid red`;
              }
              gotoIdx = els.indexOf(el);
              break;
            }
          }
          if (gotoIdx > -1) {
            dispatch({
              type: "DID_SCROLL",
              idx: gotoIdx
            } as DidScrollAction);
          }
        }
      }, 1000 / 24);
    };
    if (listRef.current) {
      const node = listRef.current;
      node.addEventListener("scroll", handler);
      return () => node.removeEventListener("scroll", handler);
    }
  }, [childSelector]);

  return (
    <CarouselElement {...props} data-carousel>
      <ScrollContainer
        ref={listRef}
        onScroll={onScroll}
        data-carousel-scroll-container
      >
        <ScrollTrack data-carousel-scroll-track>{children}</ScrollTrack>
      </ScrollContainer>
      <ScrollButton
        data-back
        onClick={() => dispatch({ type: "BACKWARD" })}
        hidden={!state.back}
        aria-label="Show previous item"
      >
        <ChevronLeft height={15} width={10} />
      </ScrollButton>
      <ScrollButton
        data-forward
        onClick={() => dispatch({ type: "FORWARD" })}
        hidden={!state.forward}
        aria-label="Show next item"
      >
        <ChevronRight height={15} width={10} />
      </ScrollButton>
    </CarouselElement>
  );
};

export default Carousel;
