import { pxToRem } from "@input-output-hk/px-to-rem";
import Link, { LinkProps } from "next/link";
import styled from "styled-components";
import CaretIcon from "../icons/Caret";

export const StyledAnchor = styled.a`
  align-items: center;
  border-radius: 0.25rem;
  color: var(--theme-color-primary);
  cursor: pointer;
  font-weight: var(--default-text-weight-bold);
  display: inline-flex;
  justifiy-content: center;
  margin: 0.4rem 0.2rem 0 -0.625rem;
  padding: 0.3125rem 0.625rem;
  text-align: center;
  text-decoration: none;

  &:hover {
    color: var(--theme-color-secondary);
  }
  &:focus {
    outline: ${pxToRem(2)} solid var(--default-focus-color);
  }
  &[disabled] {
    filter: grayscale(1);
    opacity: 0.4;
    pointer-events: none;
  }
`;

export const StyledIcon = styled(CaretIcon)`
  transform: rotate(-90deg);
  margin-left: var(--spacing-default);
`;

const LinkButton: React.FC<
  LinkProps & React.ComponentPropsWithoutRef<typeof StyledAnchor>
> = ({ children, className, href, ...rest }) => (
  <Link href={href} passHref {...rest}>
    <StyledAnchor className={className}>
      {children}
      <StyledIcon />
    </StyledAnchor>
  </Link>
);

export default LinkButton;
