import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import { useSharedState } from "../../hooks/useSharedState";

export const PageSearchInputOnScreenSentinal = () => {
  const { ref, inView } = useInView();
  const [, setIsInView] = useSharedState("page-search-input-in-view", inView);
  useEffect(() => {
    setIsInView(inView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);
  return <Sentinal ref={ref} />;
};
const Sentinal = styled.span``;
