import React from "react";
import styled from "styled-components";
import { Item } from "../../data/db/types";
import ContentCard from "../item/content-card/ContentCard";
import { forTabletPortraitDown } from "../../tokens/media-queries";

const LargeContentCard = styled(ContentCard)`
  grid-column: 1 / 3;

  @media ${forTabletPortraitDown} {
    display: none;
  }
`;

const SideFeaturedItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-large);
`;

const FeaturedContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--spacing-large);

  @media ${forTabletPortraitDown} {
    display: flex;
    flex-direction: column-reverse;
  }

  & [data-content-card] {
    overflow: hidden;
  }
`;

const AdditionalResponsiveContentCard = styled(ContentCard)`
  display: none;

  @media ${forTabletPortraitDown} {
    display: flex;
  }
`;

type Props = {
  featuredItems: Item[];
};

const DashboardFeaturedContent: React.FC<Props> = ({ featuredItems }) => {
  if (!featuredItems.length) {
    return null;
  }
  const primaryFeaturedItem: Item | undefined = featuredItems[0];
  const sideMenuFeaturedItems = featuredItems.slice(1, 5);
  const sideMenuAdditionalFeaturedItem: Item | undefined = featuredItems[5];
  const carouselFeaturedItems = featuredItems.slice(6);
  return (
    <FeaturedContainer>
      <LargeContentCard appearance="large" key={0} item={primaryFeaturedItem} />
      <SideFeaturedItemsContainer data-side-featured-items>
        {sideMenuFeaturedItems?.map((item, idx) => {
          return <ContentCard appearance="inline" key={idx} item={item} />;
        })}
        <AdditionalResponsiveContentCard
          appearance="inline"
          key={5}
          item={sideMenuAdditionalFeaturedItem}
        />
      </SideFeaturedItemsContainer>
      {carouselFeaturedItems?.map((item, idx) => {
        return <ContentCard key={idx} item={item} />;
      })}
    </FeaturedContainer>
  );
};

export default DashboardFeaturedContent;
