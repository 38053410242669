import { css } from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { forTabletPortraitOnly } from "../../tokens/media-queries";

export const dashboardStickyHeaderVariables = css`
  --dashboard-header-gutter: ${pxToRem(20)};
  --dashboard-avatar-size: ${pxToRem(60)};
  --dashboard-header-height: calc(
    var(--dashboard-avatar-size) + calc(var(--dashboard-header-gutter) * 2)
  );
  --dashboard-search-width: calc(
    100% - calc(var(--dashboard-avatar-size) + var(--dashboard-header-gutter))
  );
  @media ${forTabletPortraitOnly} {
    --dashboard-search-width: calc(100% - ${pxToRem(30)});
  }
`;
