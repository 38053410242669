import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import colors from "../../tokens/colors";
import LinkButton from "../link-button/LinkButton";

export const FeaturedDashboardSectionContainer = styled.div`
  background-color: ${colors.secondary.tyneFog};
  padding-left: var(--responsive-content-gutter);
  padding-right: var(--responsive-content-gutter);
  margin-left: calc(var(--responsive-content-gutter) * -1);
  margin-right: calc(var(--responsive-content-gutter) * -1);
`;

const DashboardSectionElement = styled.section`
  padding-top: ${pxToRem(30)};
  padding-bottom: ${pxToRem(30)};
  &:not(:nth-child(2)) {
    border-bottom: var(--card-stroke);
  }
`;
const DashboardSectionTitle = styled.h2`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: ${pxToRem(26)};
  line-height: ${pxToRem(36)};
`;
const DashboardSectionSubtitle = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(28)};

  ${DashboardSectionTitle} + & {
    margin-top: var(--spacing-xsmall);
  }
`;
const DashboardSectionContent = styled.div`
  margin-top: var(--spacing-large);
`;

type Props = {
  title: string;
  subtitle?: string;
  linkTo?: string;
  linkToLabel?: string;
};
const DashboardSection: React.FC<Props> = ({
  title,
  subtitle,
  linkTo,
  linkToLabel,
  children
}) => {
  return (
    <DashboardSectionElement>
      {title && <DashboardSectionTitle>{title}</DashboardSectionTitle>}
      {subtitle && (
        <DashboardSectionSubtitle>{subtitle}</DashboardSectionSubtitle>
      )}
      <DashboardSectionContent>{children}</DashboardSectionContent>
      {linkTo && linkToLabel && (
        <LinkButton href={linkTo}>{linkToLabel}</LinkButton>
      )}
    </DashboardSectionElement>
  );
};

export default DashboardSection;
